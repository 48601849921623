@import '../../styles/globals';

.service-area-page {
	display: flex;
	flex-direction: column;
	flex: 1;
	transition: margin, 0.3s;

	&.team-page-offset {
		margin-left: var(--merchant-navigation-container-width);
	}

	.service-area-filter-bar {
		margin-bottom: 36px;
	}

	.title {
		font-family: $font-stack;
		font-size: $font-big-2;
		font-weight: 600;
		width: 100%;
		margin: 0;
	}

	.header {
		display: flex;
		flex-wrap: nowrap;
		height: 64px;
		padding: 8px 40px 8px 40px;
		background: white;
		align-items: center;
	}

	.main {
		display: flex;
		flex-direction: column;
		flex: 1;
		margin: 24px 24px 24px 40px;
	}

	.map-tools-description {
		margin-bottom: 24px;
		color: var(--gray-600);
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.map-tools-description .description {
		margin-right: 16px;
	}
}

.service-area-secondary-text {
	color: $g-600;
}
