@import '../../../../styles/globals.scss';

.componentBackdrop {
	background-color: var(--gray-50);
	width: 98%;
	height: 100vh;
	position: absolute;
	top: 0;
	z-index: 1000;
	padding: 16px;
}

.templateDetails {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding: 16px 32px;
	margin: auto;
	max-width: 1100px;
	height: 100%;
	background-color: var(--white-bg);

	&Title {
		font-size: 20px;
		margin-bottom: 16px;
		font-weight: $font-weight-semi-bold;
	}

	.Content {
		display: flex;
		flex: 1;
		justify-content: space-between;
		overflow: auto;
		margin-bottom: 16px;

		&Title {
			font-size: 16px;
			margin-bottom: 8px;
			font-weight: $font-weight-semi-bold;
		}

		&Subtitle {
			font-size: 14px;
			color: var(--gray-600);
			margin-bottom: 6px;
		}

		&Form,
		&Preview {
			display: flex;
			flex: 1;
			flex-direction: column;
			max-width: 50%;

			&FullWidth {
				max-width: 100%;
			}
		}

		&Form {
			margin-right: 64px;

			&InputLabel {
				font-size: 12px;
				color: var(--gray-600);
				margin-bottom: 0;
				font-weight: $font-weight-semi-bold;

				&Required {
					&::before {
						margin: 0 2px;
						content: '*';
						color: $light-red;
					}
				}
			}

			.ant-select:not(.ant-select-customize-input) .ant-select-selector {
				border: 1px solid $gray-400;
				box-sizing: border-box;
				border-radius: 4px;
			}
		}

		&Preview {
			&Container {
				display: flex;
				flex: 1;
				position: relative;
				margin-bottom: 60px;

				.contentErrorMessageContainer {
					position: absolute;
					top: 0;
					bottom: 0;
					left: 0;
					right: 0;
					z-index: 1000;
					// TODO: move to variables
					background: rgba(255, 255, 255, 0.9);
					display: flex;
					flex-direction: column;
					justify-content: center;
					align-items: center;
					padding: 20px;
					text-align: center;

					span {
						margin-bottom: 10px;
					}
				}

				iframe {
					width: 100%;
					height: 100%;
					background-color: var(--gray-150);
					border: 1px solid var(--gray-300);

					body {
						margin: 0;
					}
				}
			}
		}
	}

	.Footer {
		width: 100%;
		display: flex;

		[class~='ant-btn-link'] {
			&:first-child {
				margin-right: auto;
			}
		}
	}

	.languageContent {
		font-family: monospace;
	}
}
